<script>
export default {
  name: "promiseDC"
};
</script>


  <template>
    <div class="card-container">
      <div class="card">
        <h2>{{ $t('message.skillsH2') }}</h2>
        <p>{{ $t('message.skills') }}</p>
      </div>
      <div class="card">
        <h2>{{ $t('message.promiseH2') }}</h2>
        <p>{{ $t('message.promise') }}</p>
      </div>
    </div>
  </template>


<style scoped>
.card-container {
  display: flex;
  align-items: flex-start;
  gap: 69px;
}

.card {
  display: flex;
  padding: 33px 52px;
  flex-direction: column;
  align-items: center;
  gap:0;
  flex-shrink: 1;
  border-radius: 20px;
  background: #F9F9F9;
  box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.16);
}

h2 {
  color: #5FAAB0;
  font-family: "Open Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-transform: capitalize;
}
p{
  font-family: Roboto;
  color: #646465;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: left;
}
@media (max-width: 1260px) {
  .card-container {
    margin: 24px 0 24px 0;
    gap: 28px;
  }
  .card {
    padding: 17px 35px;
    max-width: 400px;
    border-radius: 10px;
    line-height: 1.55;
  }

  h2 {
    text-align: left;
    font-size: 20px;
    width: 100%;
  }
  p{
    font-size: 16px;
    margin: 0;
  }
}
@media (max-width: 768px) {
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 30px;
    margin: 0 0 65px 0;
    padding: 0 10px;
  }
  .card {
    border-radius: 8px;
    line-height: 1.55;
    padding: 17px 35px;
    min-height: 220px;
    max-width: 279px;
  }
  h2 {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: left;
  }
  p{
    color: #565657;
    font-size: 14px;
    margin: 0;
    line-height: 160%;
  }
}
</style>