<template>
  <div class="container">
    <router-view />
    <hpFooter />
  </div>
</template>


<script>
import hpFooter from './components/hpFooter.vue'

export default {
  name: 'App',
  components: {
    hpFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html {
  scroll-behavior: smooth;
}

.container{
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 1260px) {
  .container{
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    .container {
      padding: 0 0;
      margin: 0 10px;
    }
  }
}
</style>
