const messages = {
    // hpValue
    en: {
        message: {
            // hpHeader
            header1: "Skills",
            header2:"Career",
            header3:"Contact",
            // hpIntro
            introButton: "Learn more",
            introText: "In our customers' exciting and challenging projects, we combine innovative IT solutions with our many years of experience in the world of sport and other sectors to present a solution that is perfectly tailored to our customer.",
            // hpPromise
            skills:"We combine modern IT systems with our core knowledge in the world of sport and other areas. We therefore have the necessary technical know-how to design the most advantageous solution for your operation.",
            promise: "We guarantee you individual solutions within which you decide how extensive our support should be. At your request, we will create a tailor-made service concept for you and thus offer you the most ideal support afterwards.",
            skillsH2:"Skills",
            promiseH2: "Promise",
            // hpValue
            valueH1: "Our values",
            textBox1: 'The combination of IT and sports in our company name already suggests that our projects cannot be pigeonholed. Our wide-ranging focus on "Special IT Services", both nationally and internationally, shows very well where our strengths and expertise lie.',
            textBox2: "With our holistic view of a project, many years of experience and strong customer focus, we are able to tailor our projects to the unique tasks of our partners.",
            textBox3: "datacapo's vision is to create individual solutions for our partners through the use of special IT solutions in combination with innovative technologies. In the realization of all projects, we place great emphasis on adhering to the values, for which our company stands with its name.",
            readMore: "Read more",
            card1H2: "Individuality",
            card2H2: "Precision",
            card3H2: "Flexibility",
            card4H2: "Reliability",
            card1: "Every customer has individual needs, which we take into account when implementing solutions. Our extensive know-how enables us to realize a perfectly coordinated, innovative solution for our customers.",
            card2: "A precise and meticulous approach in the sub-areas of complex processes is a significant key factor in our company to guarantee a convincing overall result.",
            card3: "Due to the dynamic nature of larger projects with many participants, plans often change. It is therefore necessary to react quickly to changing requirements, re-evaluate and find alternative solutions.",
            card4: "In a respectful interaction with our customers, reliability plays an important role. This is reflected not only in the consistent quality of our products, but also in the scheduling of order processing.",
            // hpSkills
            skillsH1: "Skills",
            skills1H2: "B2B IT Services",
            skills2H2: "Timing for Endurance Sports Events",
            skills2H2M: "Timing for Endurance Sports Events",
            skills3H2: "Software Solutions for Sports Events",
            skills4H2: "Software Development",
            skills1: "Using applications within a local and secure working environment while deciding for yourself which data is stored in a local network or in a cloud, is among the challenges that a modern company has been facing for some time now.",
            skills1_1:"In recent years, however, work processes have evolved and daily work is increasingly taking place outside the company and therefore also outside a secure network infrastructure. Working from a home office is becoming increasingly important in many companies and also plays an important role in the work-life balance of many people. IT service providers are also expected to be able to solve problems quickly and flexibly without necessarily being on site.",
            skills1_2: "All these developments make it necessary for the IT infrastructures of modern companies to be adapted to these new requirements. Our core competencies include planning and setting up a secure IT infrastructure within a company that takes into account the aforementioned possibilities, meets the highest requirements in terms of security and data protection and is also scalable. We are happy to advise you on the topics of Office 365, home office or cloud telephony and take your individual requirements into account.",
            skills2: "Events are logistical challenges that have to be handled with precision in order to create a special experience for participants and spectators. Mobile internet and RFID technologies have significantly simplified the workflow in timing at sports events and have created additional technical possibilities to offer a comprehensive service around timing.",
            skills2_1: "Our solutions are scalable from small events with just a few participants on the weekend to internationally organized large-scale events. We will find the perfect solution for your requirements and can support you with our services in various areas before, during and after your event.",
            skills2_2: "Our ambition is not only to offer accurate timing, but also to provide the organizer with a perfectly tailored solution that includes additional seamless services from a single source, such as online registration, app usage with GPS tracking or live display of participant data on the internet and television. We have developed modular and combinable solutions for these requirements that make no compromises in the areas of high availability, scalability and security and offer a level of reliability that makes a decisive contribution to the overall success of an event.",
            skills3: "Larger projects often require a secure, redundant and, above all, scalable IT infrastructure. A few years ago, when working on an international sports event, this prompted us to stop using existing software and develop a much more powerful system that meets these requirements.",
            skills3_1:"Over an extended period of time, our online registration system transformed into a much more comprehensive system that organizers can use for various purposes throughout the year. In addition to payment transactions for online registrations, the overall system also offers many other services that a registered user can use. Our strengths lie above all in the flexibility offered by the integration and connection to other existing systems.",
            skills3_2:"Regardless of whether you only want to commission just the online registration with e-payment for an event, or want to host our software packages under your own name in your own infrastructure, we will find a solution for you. Under the product name XPLORACE, we have also developed an app primarily for participants in running events, which supports an event primarily in the area of live tracking and offers added value for every event.",
            skills4: "Whenever a piece of the puzzle is missing in a customer project that is not available off the shelf, an individual implementation with all possibilities can be more advantageous. Our development department is the right partner for a solution.",
            skills4_1:"The individual wishes of our customers are our top priority, and our competent team, consisting of technology experts and software developers, creates solutions that are perfectly tailored to the individual requirements of the project. It starts with a simple interface adaptation into other systems and extends to complete solutions like the registration platform XPLORACE, which has been continuously expanded and updated over many years.",
            skills4_2:"As we enter the year 2024, our development team now consists of 12 people who support various projects from small to large with their expertise. It is important to us, to not only launch your product but also be available for its further development and other areas. Thus, our support can extend to infrastructure planning, server hosting, customer support, and e-payment, to name just a few possibilities.",
            careerH1:"Career",
            careerText_1:"The combination of IT and Sports in our company’s name already underlines that our projects are not standardised but always challenge to compete . Our wide range of \"Special IT Services\" guarantee a variety of innovative activities on a national and international scale.",
            careerText_2:"In our independent and owner-managed company, 30 employees are currently working on exciting and challenging projects, forming a creative mix of technology experts, software developers and innovative employees, who as a team are responsible for ",
            jobDescription1:"A team player (m/f/d) with an independent and result-oriented work approach, who seeks the challenge within highly complex processes.",
            jobDescription2:"A team player (m/f/d) with an independent and result-oriented work approach, who seeks the challenge within highly complex processes.",
            jobDescription3:"Do you want to become part of the datacapo team? We offer an innovative working environment in a company with flat hierarchies, dedicated to exciting and challenging tasks and activities. Datacapo is also a recognized training company for the common apprenticeship professions in the IT sector.",
            job1H3:"Your tasks",
            jobText1:"As a Frontend Developer at datacapo IT sports services GmbH, you and our team work on highly interesting tasks that extend the functionality of our software solutions across all end devices. During the technical conception of our solution, you will support our project managers with your excellent knowledge. You will work in a team of developers on the realization of the website for a major sporting event that takes place annually. You can contribute with your knowledge and skills to realize new features and revise existing ones. ",
            job1_1H3:   "Your profile",
            jobText4:"Do you occasionally need some extra income? Or would you like to earn some money alongside your studies? Would you like to be part of national and major international sports events, or are you interested in the technology behind the timing? We handle the technical management and timing at various sports events from March to September and are looking for YOU to strengthen our team! At exciting events that take place over weekends, you will have the opportunity to get to know fascinating technologies and earn some extra money on the side without being tied down!",
            jobText1_1: "Team player (m/f/d) with a passion for problem-solving and discovering new possibilities",
            jobText1_2: "Enjoy implementing UX and UI-optimized websites",
            jobText1_3: "Very good knowledge in HTML 5, CSS3, TypeScript, and ideally Vue.js with at least 2 years of experience",
            jobText1_4: "Experience with mobile websites and responsive design",
            jobText1_5: "Basic knowledge in PHP",
            jobText1_6: "Basic knowledge of design systems like Adobe XD or Figma",
            jobText1_7: "Proficient in using Git",
            jobText1_8: "Very good English language skills, both written and spoken, knowledge of the German language",
            job1_2H3:"What we can offer you",
            jobText12_1: "Structured onboarding into a permanent employment relationship",
            jobText12_2: "Flat hierarchies, open communication, and a long-term perspective in a motivated, young team and with interesting clients",
            jobText12_3: "Diverse tasks with the freedom to contribute and implement your own ideas",
            jobText12_4: "Pleasant, modern, and collegial working environment",
            jobText12_5: "Flexible working hours",
            jobText12_6: "Appropriate compensation",
            jobText42_1: "Structured onboarding into a permanent employment relationship",
            jobText42_2: "Flat hierarchies, open communication, and a long-term perspective in a motivated, young team and with interesting clients",
            jobText42_3: "Diverse tasks with the freedom to contribute and implement your own ideas",
            jobText42_4: "Pleasant, modern, and collegial working environment",
            jobText42_5: "Flexible working hours",
            jobText42_6: "Appropriate compensation",
            jobText2:"As a Senior Full-Stack Developer at datacapo IT sports services GmbH, you and our team work on highly interesting tasks that extend the functionality of our software solutions across all end devices. During the technical conception of our solution, you will support our project managers with your excellent knowledge. You will work in a team of developers on the realization of the website for a major sporting event that takes place annually. You can contribute with your knowledge and skills to realize new features and revise existing ones.",
            jobRequirement1: "Team player (m/f/d) with a passion for finding solutions and discovering new possibilities",
            jobRequirement2: "Excellent knowledge with at least 3 years of experience in JavaScript (preferably Vue.js)",
            jobRequirement3: "Excellent knowledge with at least 3 years of experience in Laravel",
            jobRequirement4: "Proficient in using Git",
            jobRequirement5: "Very good English language skills, both written and spoken, and knowledge of the German language",
            jobBenefit1: "Structured onboarding into a permanent employment relationship",
            jobBenefit2: "Flat hierarchies, open communication, and a long-term perspective in a motivated, young team and with interesting clients",
            jobBenefit3: "Diverse tasks with the freedom to contribute and implement your own ideas",
            jobBenefit4: "Pleasant, modern, and collegial working environment",
            jobBenefit5: "Flexible working hours",
            jobBenefit6: "Attractive compensation",
            becomePartOfTeam: "Do you want to become part of the datacapo team?",
            jobOffer: "We offer an innovative working environment in a company with flat hierarchies, dedicated to exciting and challenging tasks and activities. datacapo is also a recognized training company for the common apprenticeship professions in the IT sector. Convince us with your commitment and your qualifications that we absolutely need you as an employee!",
            unsolicitedApplication: "Unsolicited Application m/f/d",
            address:"Address",
            phone:"Phone",
            imprint:"Imprint",
            privacyPolicy:"Privacy policy",
            contact:"Contact",
            apply: "Apply",
            firstParagraph: "The combination of IT and sports in our company name already suggests that our projects cannot be pigeonholed. Our wide-ranging orientation in 'Special IT-Services', both nationally and internationally, clearly shows the areas in which our strengths and competencies lie.",
            secondParagraph: "In our independent and owner-managed company, 26 employees currently work on exciting and challenging projects, forming a creative mix of technology experts, software developers, and innovative staff, who as a team are responsible for the sustainable success of our company. With our holistic view of a project, long-standing experience, and pronounced customer orientation, we are able to tailor our projects to the interesting tasks of our partners.",
            firstParagraphTablet: "The combination of IT and sports in our company name already suggests that our projects cannot be pigeonholed. Our wide-ranging orientation in 'Special IT-Services', both nationally and internationally, clearly shows the areas in which our strengths and competencies",
            secondParagraphTablet: "lie. In our independent and owner-managed company, 26 employees currently work on exciting and challenging projects, forming a creative mix of technology experts, software developers, and innovative staff, who as a team are responsible for the sustainable success of our company. With our holistic view of a project, long-standing experience, and pronounced customer orientation, we are able to tailor our projects to the interesting tasks of our partners.",
            company: "Company",
            manager: "CEO",
            vatId: "VAT identification number",
            regNr: "Register Number",
            regCo: "Registry court",
            }
    },
    de: {
        message: {
            // hpHeader
            header1: "Kompetenzen",
            header2:"Karriere",
            header3:"Kontakt",
            // hpIntro
            introButton: "Mehr Erfahren",
            introText: "In den spannenden und herausfordernden Projekten unserer Kunden kombinieren wir innovative IT Lösungen mit unserer\n" +
                "            langjährigen Erfahrung aus der Sportwelt oder anderen Bereichen, um als Ergebnis eine perfekt auf den Kunden\n" +
                "            abgestimmte Lösung zu präsentieren.",
            // hpPromise
            skills:"Wir vereinen moderne IT-Technologien mit unseren Kernkompetenzen aus der Welt des Sports und anderen\n" +
                "          Bereichen. Damit verfügen wir über das notwendige technische Know How, um für Sie die vorteilhafteste Lösung\n" +
                "          für Ihren Betrieb individuell auszugestalten.",
            promise: "Wir garantieren Ihnen individuelle Lösungen innerhalb derer Sie selbst entscheiden, wie umfangreich " +
                "unsere Betreuung ausfällt. Auf Ihren Wunsch erstellen wir ihnen ein maßgeschneidertes Servicekonzept und " +
                "bieten Ihnen somit eine ideale Betreuung danach",
            skillsH2:"Kompetenzen",
            promiseH2: "Versprechen",
            // hpValue
            valueH1: "Unsere Werte",
            textBox1: "Die Kombination von IT und Sports in unserem Firmennamen lässt bereits vermuten, dass sich unsere\n" +
                "                        Projekte nicht in eine Schublade stecken lassen. Unsere breitgefächerte Ausrichtung von „Special\n" +
                "                        IT-Services“, sowohl national als auch international, zeigt sehr gut in welchen Bereichen unsere\n" +
                "                        Stärken und Kompetenzen liegen.",
            textBox2: "Mit unserem ganzheitlichen Blick für ein Projekt, der langjährigen Erfahrung und ausgeprägten Kundenorientierung sind wir in der Lage unsere Projekte auf die interessanten Aufgaben unserer Partner individuell anzupassen.",
            textBox3: "Die Vision von datacapo ist, durch den Einsatz spezieller\n" +
                "          IT – Lösungen in Kombination mit innovativen Technologien, individuelle Lösungen für unsere Partner zu kreieren.\n" +
                "          Dabei legen wir bei der Realisierung aller Projekte sehr viel Wert auf die Einhaltung folgender Werte, für die das\n" +
                "          Unternehmen mit seinem Namen steht.",
            readMore: "Mehr Lesen",
            card1H2: "Individualität",
            card2H2: "Präzision",
            card3H2: "Flexibilität",
            card4H2: "Zuverlässigkeit",
            card1: "Jeder Kunde hat individuelle Bedürfnisse, auf die wir bei der Lösungsumsetzung auch eingehen. Unser\n" +
                "            umfangreiches Know-how ermöglicht es für unsere Kunden eine perfekt abgestimmte, innovative Lösung zu\n" +
                "            realisieren.",
            card2: "Eine präzise und sorgfältige Arbeitsweise in den Teilbereichen von komplexen Prozessen ist ein bedeutender\n" +
                "            Schlüsselfaktor in unserem Unternehmen, um ein überzeugendes Gesamtergebnis zu garantieren.",
            card3: "Aufgrund der Eigendynamik in größeren Projekten mit vielen Beteiligten kommt es häufig zu Planänderungen.\n" +
                "            Darum ist es notwendig schnell auf sich ändernde Anforderungen zu reagieren, neu zu bewerten und alternative\n" +
                "            Lösungswege zu finden.",
            card4: "In einem respektvollen Umgang mit unseren Kunden spielt Zuverlässigkeit eine bedeutende Rolle. Diese spiegelt\n" +
                "            sich nicht nur in der gleichbleibenden Qualität unserer Produkte wider, sondern wird auch in der zeitlichen\n" +
                "            Planung einer Auftragsabwicklung großgeschrieben.",
            // hpSkills
            skillsH1: "Kompetenzen",
            skills1H2: "B2B IT - Dienstleistungen",
            skills2H2: "Timing für Ausdauersportveranstaltungen",
            skills2H2M: "Timing für Ausdauersport- veranstaltungen",
            skills3H2: "Softwarelösungen für Sportveranstaltungen",
            skills4H2: "Softwareentwicklung",
            skills1: "Applikationen innerhalb einer lokalen und sicheren Arbeitsumgebung zu nutzen und dabei selbst zu entscheiden,\n" +
                "            welche Daten in einem lokalen Netzwerk oder in einer Cloud gespeichert werden, gehört zu den Herausforderungen,\n" +
                "            denen sich ein modernes Unternehmen schon seit einiger Zeit stellen muss.",
            skills1_1:"In den letzten Jahren haben sich\n" +
                "          Arbeitsprozesse aber weiterentwickelt und die tägliche Arbeit findet immer häufiger außerhalb der Firma und\n" +
                "          damit auch außerhalb einer sicheren Netzwerk - Infrastruktur statt. Die Arbeit aus einem Home-Office nimmt in\n" +
                "          vielen Unternehmen immer mehr an Bedeutung zu und spielt bei vielen Menschen auch in der Work Life Balance eine\n" +
                "          wichtige Rolle. Von einem IT-Dienstleister wird ebenso erwartet, dass er Probleme schnell und flexibel lösen\n" +
                "          kann, ohne unbedingt vor Ort zu sein.",
            skills1_2:"All diese Entwicklungen machen es notwendig, dass die IT-Infrastrukturen\n" +
                "          moderner Firmen an diese neuen Anforderungen angepasst werden. Zu unseren Kernkompetenzen zählt die Planung und\n" +
                "          Einrichtung einer sicheren IT-Infrastruktur innerhalb einer Firma, die die genannten Möglichkeiten\n" +
                "          berücksichtigt, höchste Anforderungen in Bezug auf Sicherheit und Datenschutz erfüllt und außerdem skalierbar\n" +
                "          ist. Wir beraten Sie gerne zu den Themen Office 365, Home-Office oder Cloud Telefonie und nehmen dabei auf ihre\n" +
                "          individuellen Anforderungen Rücksicht.",
            skills2:"Veranstaltungen sind logistische Herausforderungen, die auf den Punkt genau abgewickelt werden müssen,\n" +
                "            um für Teilnehmer und Zuschauer ein besonderes Erlebnis zu schaffen.",
            skills2_1:"Unsere Lösungen sind von der kleinen Veranstaltung mit wenigen Teilnehmern am Wochenende\n" +
                "          bis hin zur international organisierten Großveranstaltung skalierbar, wir finden auch gerne für Sie die auf ihre\n" +
                "          Ansprüche perfekt abgestimmte Lösung und können Sie vor, während und nach ihrer Veranstaltung in\n" +
                "          unterschiedlichen Bereichen mit unseren Dienstleistungen unterstützen.",
            skills2_2:"Unser Anspruch ist es\n" +
                "          dabei, nicht nur eine korrekte Zeitmessung anzubieten, sondern dem Veranstalter eine auf ihn perfekt abgestimmte\n" +
                "          Lösung bereitzustellen, die weitere medienbruchfreie Serviceleistungen aus einer Hand, wie z.B.\n" +
                "          Onlineregistrierung, App Nutzung mit GPS-Tracking oder Live-Darstellung von Teilnehmerdaten im Internet und\n" +
                "          Fernsehen beinhalten kann. Wir haben für diese Anforderungen modulare und miteinander kombinierbare Lösungen\n" +
                "          entwickelt, die in den Bereichen Hochverfügbarkeit, Skalierbarkeit und Sicherheit keine Kompromisse eingehen und\n" +
                "          in der Summe eine Zuverlässigkeit bieten, mit der wir einen entscheidenden Beitrag zum Gesamterfolg einer\n" +
                "          Veranstaltung leisten.",
            skills3:"Größere Projekte benötigen oft eine sichere, redundante und vor allem skalierbare IT-Infrastruktur.\n" +
                "            Dies hat uns vor einigen Jahren in der Zusammenarbeit mit einer internationalen Sportveranstaltung\n" +
                "            veranlasst nicht mehr auf bestehende Software zurückzugreifen, sondern ein deutlich mächtigeres System zu\n" +
                "            entwickeln, das diesen Anforderungen genügt.",
            skills3_1:"Über einen längeren Zeitraum entstand so aus einem Online Anmeldesystem ein deutlich\n" +
                "          umfänglicheres System, das die Organisatoren über das ganze Jahr für unterschiedliche Zwecke nutzen können.\n" +
                "          In dem Gesamtsystem werden außer Zahlungsvorgängen bei Onlineanmeldungen auch viele andere\n" +
                "          Servicedienstleistungen angeboten, die ein registrierter User über das ganze Jahr nutzen kann. Unsere Stärken\n" +
                "          liegen hierbei vor allem in der Flexibilität durch die Integration oder Anbindung an andere bereits bestehende\n" +
                "          Systeme.",
            skills3_2:"Egal ob sie nur die Onlineanmeldung incl. E-Payment für eine Veranstaltung beauftragen wollen, oder\n" +
                "          unsere Softwarepakete unter eigenem Namen in einer eigenen Infrastruktur von uns hosten lassen wollen, wir\n" +
                "          finden einen Weg für Sie. Unter dem Produktnamen XPLORACE haben wir auch eine App in erster Linie für die\n" +
                "          Teilnehmer von Laufveranstaltungen entwickelt, die ein Event vor allem im Bereich Live-Tracking unterstützt und\n" +
                "          für jede Veranstaltung einen Mehrwert bietet.",
            skills4:"Immer dann, wenn in einem Kundenprojekt ein Puzzleteil fehlt, das es nicht von der Stange gibt oder eine\n" +
                "            individuelle Umsetzung mit all seinen Möglichkeiten vorteilhafter sein kann, ist unsere Entwicklungsabteilung\n" +
                "            der richtige Partner für eine Lösung.",
            skills4_1:"Die individuellen Wünsche unserer Kunden stehen bei uns an oberster Stelle\n" +
                "          und unser kompetentes Team, bestehend aus Technologieexperten und Softwareentwicklern kreiert Lösungen, die\n" +
                "          perfekt auf die individuellen Anforderungen des Projekts abgestimmt sind. Es beginnt mit einer einfachen\n" +
                "          Schnittstellenadaptierung in andere Systeme bis hin zu Komplettlösungen wie der Registrierungsplattform\n" +
                "          XPLORACE, die über viele Jahre immer weiter ausgebaut und dabei auch aktualisiert wurde.",
            skills4_2:"Zum Start ins Jahr 2024\n" +
                "          besteht unser Entwicklungsteam aus inzwischen 12 Personen, die zusammen unterschiedliche Projekte von klein bis\n" +
                "          groß mit ihrem Fachwissen unterstützen. Uns ist es dabei wichtig, dass wir ihr Produkt nicht nur an den Start\n" +
                "          bringen, sondern ihnen auch bei der Weiterentwicklung und in anderen Bereichen zur Verfügung stehen. So kann\n" +
                "          unsere Unterstützung auch auf die Bereiche Infrastrukturplanung, Serverhosting, Kundensupport und E-Payment\n" +
                "          ausgedehnt werden, um nur einige Möglichkeiten zu nennen.",
            // hpCareer
            careerH1:   "Karriere",
            careerText_1:"Die wichtigste Ressource eines Unternehmens sind die eigenen Mitarbeiter. Ein guter Teamgeist,\n" +
                "          technische Kreativität und der Wille außergewöhnliches zu erreichen sind bei uns die Voraussetzung für die\n" +
                "          Realisierung ehrgeiziger Kundenprojekte",
            careerText_2:"In unserer unabhängigen und inhabergeführten Firma arbeiten derzeit 26 Mitarbeiter an spannenden und\n" +
                "          herausfordernden Projekten und bilden dabei eine kreative Mischung aus Technologieexperten,\n" +
                "          Softwareentwicklern und innovativen Mitarbeitern, welche als Team die Verantwortung für den nachhaltigen\n" +
                "          Erfolg unserer Firma tragen.",
            jobDescription1:"Ein Teamplayer (m/w/d) mit selbstständiger und ergebnisorientierter Arbeitsweise, der die Herausforderung innerhalb hochkomplexer Prozesse sucht",
            jobDescription2:"Ein Teamplayer (m/w/d) mit selbstständiger und ergebnisorientierter Arbeitsweise, der die Herausforderung innerhalb hochkomplexer Prozesse sucht",
            jobDescription3:"Du willst Teil des datacapo-Team werden? Wir bieten ein innovatives Arbeitsumfeld in einem Unternehmen mit\n" +
                "      flachen Hierarchien, welches sich spannenden und herausfordernden Aufgaben und Tätigkeiten widmet.\n" +
                "      Datacapo ist zudem anerkannter Ausbildungsbetrieb für die gängigen Ausbildungsberufe im IT-Bereich",
            job1H3:  "Deine Aufgaben",
            jobText1:"Als Frontend Developer in der datacapo IT sports services GmbH arbeitest Du im Team an hochinteressanten\n" +
                "          Aufgaben, die die Funktionalität unserer Softwarelösungen quer über alle Endgeräte erweitert. Bei der\n" +
                "          technischen Konzeption unserer Lösung stehst Du unseren Projektmanagern mit Deinem fundierten Fachwissen zur\n" +
                "          Seite. Du arbeitest in einem Team von Entwicklern an der Realisierung der Website für ein großes Sportevent,\n" +
                "          das jährlich stattfindet. Du kannst mit deinem Wissen und deinen Fähigkeiten dazu beitragen, neue Funktionen\n" +
                "          zu realisieren und bestehende zu überarbeiten.",
            job1_1H3:   "Dein Profil",
            jobText4:"Du brauchst hin-und wieder einen Zuverdienst? Oder du möchtest dir neben deinem Studium etwas dazu verdienen? Du wärst gerne auf nationalen- sowie großen internationalen Sportveranstaltungen dabei oder du interessierst dich für die Technologie die hinter dem Timing steckt? Wir übernehmen die technische Verwaltung und das Timing auf verschiedensten Sportveranstaltungen im Zeitraum von März-September und suchen DICH als Verstärkung für unser Team! Auf interessanten Events, die über Wochenenden stattfinden hast du die Möglichkeit spannende Technologien kennenzulernen und dir nebenbei einen schnellen Zuverdienst zu sichern, ohne gebunden zu sein!",
            jobText1_1: "Teamplayer (m/w/d) mit Spaß an der Lösungsfindung und Entdeckung neuer Möglichkeiten",
            jobText1_2: "Spaß an der Umsetzung von UX- und UI-optimierten Webseiten haben",
            jobText1_3: "Sehr gute Kenntnisse in HTML 5, CSS3, TypeScript und idealerweise in Vue.js mit mindestens 2 Jahren Erfahrung",
            jobText1_4: "Erfahrung mit mobilen Websites und Responsive Design",
            jobText1_5: "Grundkenntnisse in PHP",
            jobText1_6: "Grundkenntnisse in Designsystemen wie Adobe XD oder Figma",
            jobText1_7: "sicherer Umgang mit Git",
            jobText1_8: "sehr gute Englischkenntnisse in Wort und Schrift, Kenntnisse der deutschen Sprache",
            job1_2H3:"Das bieten wir Dir",
            jobText12_1: "Strukturierte Einarbeitung in ein unbefristetes Arbeitsverhältnis",
            jobText12_2: "Flache Hierarchien, offene Kommunikation und eine langfristige Perspektive in einem motivierten, jungen Team und interessanten Auftraggebern",
            jobText12_3: "Vielfältige Aufgaben mit dem Freiraum, eigene Ideen und Erfahrungen einzubringen und umzusetzen",
            jobText12_4: "Angenehmes, modernes und kollegiales Arbeitsumfeld",
            jobText12_5: "Flexible Arbeitszeit",
            jobText12_6: "Angemessene Vergütung",
            jobText42_1: "Strukturierte Einarbeitung",
            jobText42_2: "Flache Hierarchien",
            jobText42_3: "Vielfältige Aufgaben mit dem Freiraum eigene Ideen umzusetzen",
            jobText42_4: "Angenehmes, modernes und kollegiales Arbeitsumfeld",
            jobText42_5: "Flexible Arbeitszeit",
            jobText42_6: "Angemessene Vergütung",
            jobText2:"Als Full-Stack Developer in der datacapo IT sports services GmbH arbeitest Du im Team an hochinteressanten\n" +
                "          Aufgaben, die die Funktionalität unserer Softwarelösungen quer über alle Endgeräte erweitert. Bei der\n" +
                "          technischen Konzeption unserer Lösung stehst Du unseren Projektmanagern mit Deinem fundierten Fachwissen zur\n" +
                "          Seite. Du arbeitest in einem Team von Entwicklern an der Realisierung der Website für ein großes Sportevent,\n" +
                "          das jährlich stattfindet. Du kannst mit deinem Wissen und deinen Fähigkeiten dazu beitragen, neue Funktionen\n" +
                "          zu realisieren und bestehende zu überarbeiten.",
            jobRequirement1:    "Teamplayer (m/w/d) mit Spaß an der Lösungsfindung und Entdeckung neuer Möglichkeiten",
            jobRequirement2:    "Sehr gute Kenntnisse mit mindestens 3 Jahre Erfahrung in Javascript (vorzugsweise vueJS)",
            jobRequirement3:    "Sehr gute Kenntnisse mit mindestens 3 Jahre Erfahrung in Laravel",
            jobRequirement4:    "Sicherer Umgang mit Git",
            jobRequirement5:    "Sehr gute Englischkenntnisse in Wort und Schrift, Kenntnisse der deutschen Sprache",
            jobBenefit1: "Strukturierte Einarbeitung in ein unbefristetes Arbeitsverhältnis",
            jobBenefit2: "Flache Hierarchien, offene Kommunikation und eine langfristige Perspektive in einem motivierten, jungen Team und interessanten Auftraggebern",
            jobBenefit3: "Vielfältige Aufgaben mit dem Freiraum, eigene Ideen einzubringen und umzusetzen",
            jobBenefit4: "Angenehmes, modernes und kollegiales Arbeitsumfeld",
            jobBenefit5: "Flexible Arbeitszeiten",
            jobBenefit6: "Attraktive Vergütung",
            becomePartOfTeam: "Du willst Teil des datacapo-Team werden?",
            jobOffer: "Wir bieten ein innovatives Arbeitsumfeld in einem Unternehmen mit flachen Hierarchien, welches sich spannenden und herausfordernden Aufgaben und Tätigkeiten widmet. datacapo ist zudem anerkannter Ausbildungsbetrieb für die gängigen Ausbildungsberufe im IT-Bereich. Überzeuge uns mit Deinem Engagement sowie Deinen Qualifikationen davon, dass wir Dich unbedingt als Mitarbeiter brauchen!",
            unsolicitedApplication: "Initiativ Bewerbung m/w/d",
            address:"Addresse",
            phone:"Telefon",
            imprint:"Impressum",
            privacyPolicy:"Datenschutz",
            contact:"Kontakt",
            apply: "Bewerben",
            firstParagraph: "Die Kombination von IT und Sports in unserem Firmennamen lässt bereits vermuten, dass sich unsere Projekte nicht in eine Schublade stecken lassen. Unsere breitgefächerte Ausrichtung von „Special IT-Services“, sowohl national als auch international, zeigt sehr gut in welchen Bereichen unsere Stärken und Kompetenzen liegen.",
            secondParagraph: "In unserer unabhängigen und inhabergeführten Firma arbeiten derzeit 26 Mitarbeiter an spannenden und herausfordernden Projekten und bilden dabei eine kreative Mischung aus Technologieexperten, Softwareentwicklern und innovativen Mitarbeitern, welche als Team die Verantwortung für den nachhaltigen Erfolg unserer Firma tragen. Mit unserem ganzheitlichen Blick für ein Projekt, der langjährigen Erfahrung und ausgeprägten Kundenorientierung sind wir in der Lage unsere Projekte auf die interessanten Aufgaben unserer Partner individuell anzupassen.",
            firstParagraphTablet: "Die Kombination von IT und Sports in unserem Firmennamen lässt bereits vermuten, dass sich unsere Projekte nicht in eine Schublade stecken lassen. Unsere breitgefächerte Ausrichtung von „Special IT-Services“, sowohl national als auch international, zeigt sehr gut in welchen Bereichen unsere Stärken und Kompetenzen",
            secondParagraphTablet: "liegen. In unserer unabhängigen und inhabergeführten Firma arbeiten derzeit 26 Mitarbeiter an spannenden und herausfordernden Projekten und bilden dabei eine kreative Mischung aus Technologieexperten, Softwareentwicklern und innovativen Mitarbeitern, welche als Team die Verantwortung",
            company: "Firma",
            manager: "Geschäftsführer",
            vatId: "Umsatzsteueridentifikationsnummer",
            regNr: "Registernummer",
            regCo: "Registergericht",
        }
    }
};

export default messages;
