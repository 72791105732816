
<template>
  <div>
    <hpHeader :showLinks="true" />
    <hpIntro />
    <hpPromise />
    <hpValue />
    <hpSkills />
    <hpCareer />
    <hpContact />
  </div>
</template>

<script>
import hpIntro from "@/components/hpIntro.vue";
import hpPromise from "@/components/hpPromise.vue";
import hpValue from "@/components/hpValue.vue";
import hpContact from "@/components/hpContact.vue";
import hpSkills from "@/components/hpSkills.vue";
import hpCareer from "@/components/hpCareer.vue";
import hpHeader from "@/components/hpHeader.vue";

export default {
  name: "hpHome",
  components: {hpHeader, hpIntro, hpPromise, hpValue, hpSkills, hpCareer, hpContact}
};
</script>

