import { createRouter, createWebHistory } from 'vue-router';
import hpImpressum from "../views/hpImpressum.vue";
import hpDatenschutz from "../views/hpDatenschutz.vue";
import hpHome from "../views/hpHome.vue";


const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: hpHome
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: hpImpressum
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: hpDatenschutz
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
