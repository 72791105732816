
<template>
  <h1 id="Contact">{{ $t('message.contact') }}</h1>
  <div class="contact">
    <div class="icon-group">
      <div class="icon-card">
        <div class="icon address-icon-1"></div>
        <h3>{{ $t('message.address') }}</h3>
        <p>Am Stockert 8</p>
        <p>D- 79312 Emmendingen</p>
      </div>
      <div class="icon-card">
        <div class="icon address-icon-4"></div>
        <h3>{{ $t('message.phone') }}</h3>
        <p>Sport +49 7641/9585519</p>
        <p>IT & Allgemein +49 7641/9585500</p>
      </div>
      <div class="icon-card">
        <div class="icon address-icon-2" style="width: 100px"></div>
        <h3>E-Mail</h3>
        <p>info@datacapo.com</p>
      </div>
      <!--
      <div class="icon-card">
        <div class="icon address-icon-3"></div>
        <h3>Fax</h3>
        <p>+49 7641/9585503</p>
      </div>
      -->
    </div>
  </div>
  <div class="contact-mobile">
    <div class="icon-group">
      <div class="icon-card">
        <div class="icon address-icon-1"></div>
        <div class="icon-address">
          <h3>{{ $t('message.address') }}</h3>
          <p>Am Stockert 8</p>
          <p>D- 79312 Emmendingen</p>
        </div>
      </div>
      <div class="icon-card">
        <div class="icon address-icon-4"></div>
        <div class="icon-tel">
          <h3>{{ $t('message.phone') }}</h3>
          <br>
          <p>IT & Allgemein:</p>
          <p> +49 7641/9585500</p>
          <br>
          <p>Sport:</p>
          <p> +49 7641/9585519</p>
        </div>
      </div>
      <div class="icon-card">
        <div class="icon address-icon-2"></div>
        <div class="icon-email">
          <h3>E-Mail</h3>
          <p>info@datacapo.com</p>
        </div>
      </div>
      <!--
      <div class="icon-card">
        <div class="icon address-icon-3"></div>
        <div class="icon-email">
          <h3>Fax</h3>
          <p>+49 7641/9585503</p>
        </div>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppContakt'
}
</script>

<style scoped>
.contact {
  text-align: center;
  margin-bottom: 60px;
}
.contact-mobile{
  display: none;
}

.icon-group {
  display: flex;
  justify-content: space-around;
}

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.address-icon-1 {
  background-image: url('../assets/img/Group 2489-2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px 60px;
}

.address-icon-2 {
  background-image: url('../assets/img/Group 2488-2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px 60px;
}

.address-icon-3 {
  background-image: url('../assets/img/Group 2491-2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px 60px;
}

.address-icon-4 {
  background-image: url('../assets/img/Group 2490-2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px 60px;
}
p{
  margin: 5px;
  color: #646465;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Jost;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1px;

}
h1{
  color: #DF1A12;
  font-family: "Open Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 150px;
  margin-bottom: 74px;
}
h3{
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Jost;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;
}
@media (max-width: 768px) {
.contact{
  display: none;
}
  .contact-mobile{
    display: block;
  }
  .icon-group {
    justify-content: unset;
    flex-direction: column;
  }
  .icon-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 30px;
    margin: 20px 5px 20px 5px;
  }
  .icon {
    width: 70px;
    height: 70px;
    margin: 0;
  }
  .icon-address{
    text-align: left;
  }
  .icon-email{
    text-align: left;
  }
  .icon-tel{
    text-align: left;
  }
  .address-icon-1 {
    background-image: url('../assets/img/Group 2489-2.svg');
    background-size: 35px 35px;
  }
  .address-icon-2 {
    background-image: url('../assets/img/Group 2488-2.svg');
    background-size: 35px 35px;
  }
  .address-icon-3 {
    background-image: url('../assets/img/Group 2491-2.svg');
    background-size: 35px 35px;
  }
  .address-icon-4 {
    background-image: url('../assets/img/Group 2490-2.svg');
    background-size: 35px 35px;
  }
  p{
    font-size: 14px;
    margin: 0;
  }
  h1{
    font-size: 24px;
    margin-top: 64px;
    margin-bottom: 34px;
  }
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 16px;
    margin: 0;
  }
}
</style>