<template>
  <header id="top" :class="{ 'hide-header': !isHeaderVisible }">

    <div class="flex-container-Header">
      <div class="logo-left">
        <router-link v-if="!showLinks" to="/">
          <div class="flex-logo"><img class="logo" src="../assets/img/logo_datacapo_red.svg"></div>
        </router-link>
        <img v-if="showLinks" class="logo" src="../assets/img/logo_datacapo_red.svg" @click="scrollToTop">
      </div>
      <div v-if="showLinks" class="links-right">
      <div class="flex-link"><a class="a-flex-link" href="#Kompetenzen">{{ $t('message.header1') }}</a></div>
      <div class="flex-link"><a class="a-flex-link" href="#Karriere">{{ $t('message.header2') }}</a></div>
      <div class="flex-link"><a class="a-flex-link" href="#Contact">{{ $t('message.header3') }}</a></div>
      </div>
      <div class="container">
        <div>
          <img v-if="showLinks" class="icon" src="../assets/img/Language.svg" alt="Language">
        </div>
        <div>
          <select id="languages" v-model="selectedLanguage" v-if="showLinks">
            <option value="en">EN</option>
            <option value="de">DE</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <router-link v-if="!showLinks" to="/">
        <div class="flex-logo"><img class="logo" src="../assets/img/logo_datacapo_red.svg"></div>
      </router-link>
      <img v-if="showLinks" class="logo" src="../assets/img/logo_datacapo_red.svg" @click="scrollToTop">
      <input ref="hamburgCheckbox" v-if="showLinks" type="checkbox" id="hamburg">
      <label v-if="showLinks" for="hamburg" class="hamburg">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </label>

      <nav class="topmenu">
        <div>
          <ul class="flex-topmenu">
            <div class="flex-div-topmenu"><li class="flex-link-topmenu"><a class="a-link-topmenu" href="#Kompetenzen" @click="closeMenu">{{ $t('message.header1') }}</a></li></div>
            <div class="flex-div-topmenu"><li class="flex-link-topmenu"><a class="a-link-topmenu" href="#Karriere" @click="closeMenu">{{ $t('message.header2') }}</a></li></div>
            <div class="flex-div-topmenu"><li class="flex-link-topmenu"><a class="a-link-topmenu" href="#Contact" @click="closeMenu">{{ $t('message.header3') }}</a></li></div>
            <div>
              <img v-if="showLinks" class="icon" src="../assets/img/Language.svg" alt="Language">
              <select id="languages" v-model="selectedLanguage" v-if="showLinks">
                <option value="en" @click="closeMenu">EN</option>
                <option value="de" @click="closeMenu">DE</option>
              </select>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  </header>

</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
      isHeaderVisible: true,
    };
  },
  methods: {
    closeMenu() {
      this.$refs.hamburgCheckbox.checked = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
}
  },
  mounted() {
    let lastScrollPosition = 0;
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollPosition < lastScrollPosition) {
        this.isHeaderVisible = true;
      } else {
        this.isHeaderVisible = false;
      }

      lastScrollPosition = currentScrollPosition <= 0 ? 0 : currentScrollPosition;
    };

    window.addEventListener('scroll', handleScroll);
    this.handleScroll = handleScroll; // Speichern der Funktion zur späteren Entfernung
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    selectedLanguage(newLang) {
      this.$i18n.locale = newLang;
      localStorage.setItem('preferredLanguage', newLang);
    }
  },
  created() {
    const savedLang = localStorage.getItem('preferredLanguage') || 'de';
    this.selectedLanguage = savedLang;
  },
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
#top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1260px;
  margin: 0 auto;
  background-color: white;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}
#top.hide-header {
  transform: translateY(-100%);
}
.container{
  display: flex;
  align-items: center;
  padding-left: 45px;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Open Sans";
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 20px;
  color: #DF1A12;
  cursor: pointer;
}
select:hover{
  color: #C1160F;
}
.icon{
  fill: #DF1A12;
  height: 18px;
  padding: 6px 8px 0 0;
}
p{
  padding-right: 3rem;
  font-size: 20px;
  font-weight: bold;
}
.flex-container-Header {
  margin: 0 auto;
  display: flex;
}
.links-right{
  display: flex;
  flex:1;
  align-items: center;
  justify-content: flex-end;
}
.row{
  display: none;
}
.flex-logo{
  flex-grow: 1;
}
.logo{
  width: 217px;
  height: 80px;
}
.a-flex-link{
  color: #303030;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-transform: capitalize;
  text-decoration: none;
  padding-left: 45px;
}
.a-flex-link:hover {
  color: #DF1A12;
  text-decoration: none;
}
@media (max-width: 1260px) {

  .container{
    padding: 0;
  }
  .a-flex-link{
  font-size: 20px;
  padding-left: 0;
    padding-right: 25px;
}
  .flex-container-Header {
    width: 100%;
  }
  .logo{
    width: 160px;
    padding-left: 40px;
    padding-right: 0;
  }
  p{
    font-size: 15px;
  }
}
@media (max-width: 768px) {
  .container{
    padding-left: 0;
  }
  .flex-container-Header {
    display: none;
  }
  .logo{
    width: 140px;
    padding-left: 0;
    padding-right: 120px;
  }
  p{
    font-size: 15px;
  }
  .a-flex-link{
    font-size: 20px;
    padding-right: 10px;
  }
  .row{
    display: block;
    margin: 0 auto;
    max-width: 350px;
    padding-top: 10px;
  }

  label.hamburg {
    display: block;
    background: white;
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 2px;
    float: right;
    margin-right: 10px;
    margin-top: 25px;
  }
  .line {
    position: absolute;
    height: 3px;
    width: 25px;
    background: black;
    border-radius: 8px;
    display: block;
    transition: 0.1s;
    transform-origin: center;
  }
  .line:nth-child(1) { top: 7px; }
  .line:nth-child(2) { top: 14px; }
  .line:nth-child(3) { top: 21px; }

  #hamburg:checked + .hamburg .line:nth-child(1){
    transform: translateY(7px) rotate(-45deg);
  }

  #hamburg:checked + .hamburg .line:nth-child(2){
    opacity:0;
  }

  #hamburg:checked + .hamburg .line:nth-child(3){
    transform: translateY(-7px) rotate(45deg);
  }
  nav.topmenu {
    max-height:0;
    overflow: hidden;
    transition: all 0.1s;
    font-size: 20px;
    height: 280px;

  }
  .flex-topmenu{
    display: flex;
    flex-direction: column;
    justify-content: right;
    gap: 25px;
    font-size: 30px;
    text-align: center;
    padding: 10px 0 10px 0;
  }
  .flex-div-topmenu{
    text-decoration: none;
    color: black;
  }
  .a-link-topmenu{
    text-decoration: none;
    color: black;
  }
  .a-link-topmenu:hover{
    color: #de1512;
  }
  .flex-link-topmenu{
    color:#de1512;
    list-style: none;
  }
  #hamburg:checked + .hamburg  + nav.topmenu {
    max-height: 500px;
  }
  #hamburg:checked + .hamburg .line,
  #hamburg:checked + .hamburg  + nav.topmenu {
    max-height: 600px;
  }
  input#hamburg {display:none}
}
</style>
