
<template>
  <h1>{{ $t('message.valueH1') }}</h1>
    <div class="container">
      <div class="img">
        <img class="logo" alt="DC mockup" src="../assets/img/sports-img.png">
      </div>
      <div class="TextBox">
        <p>{{ $t('message.textBox1') }}</p>
        <p>{{ $t('message.textBox2') }}</p>
        <p>{{ $t('message.textBox3') }}</p>
      </div>
      <div class="TextBox-tablet">
        <p class="p-tablet">{{$t('message.firstParagraphTablet') }}</p>
        <button class="btn-mr-tablet" v-if="!showMore" @click="toggleReadMore">
          {{$t('message.readMore') }} <span style="font-size: 0.7em; vertical-align: middle;">&#9660;</span>
        </button>

        <div v-if="showMore">
          <p class="p-tablet">{{$t('message.secondParagraphTablet')}}</p>
        </div>

      </div>
    </div>
    <div class="card-container">
      <div class="card">
        <img src="../assets/img/Group2471-2.svg" alt="Description 1" class="card-icon">
        <div class="card-text">
          <h2>{{ $t('message.card1H2') }}</h2>
          <p>{{ $t('message.card1') }}</p>
        </div>
      </div>

      <div class="card">
        <img src="../assets/img/Group-2.svg" alt="Description 2" class="card-icon">
        <div class="card-text">
          <h2>{{$t('message.card2H2') }}</h2>
          <p>{{ $t('message.card2') }}</p>
        </div>
      </div>

      <div class="card">
        <img src="../assets/img/Layer2-2.svg" alt="Description 3" class="card-icon">
        <div class="card-text">
          <h2>{{ $t('message.card3H2') }}</h2>
          <p>{{ $t('message.card3') }}</p>
        </div>
      </div>

      <div class="card">
        <img src="../assets/img/Vector-2.svg" alt="Description 4" class="card-icon">
        <div class="card-text">
          <h2>{{ $t('message.card4H2') }}</h2>
          <p>{{ $t('message.card4') }}</p>
        </div>
      </div>
    </div>


    <div class="container-mobile">
    <div class="TextBox-mobile">
      <p class="p-mobile">{{ $t('message.firstParagraph') }}</p>
      <button class="btn-mr" v-if="!showMore" @click="toggleReadMore">
        {{$t('message.readMore') }} <span style="font-size: 0.7em; vertical-align: middle;">&#9660;</span>
      </button>

      <div v-if="showMore">
        <p class="p-mobile">{{$t('message.secondParagraph') }}</p>
      </div>

      <div class="img-mobile">
        <img class="logo" alt="DC mockup" src="../assets/img/sports-img.png">
      </div>
    </div>
    </div>
  <div class="slide-werte-container">
    <div class="slide-werte-box">
      <div>
        <div class="slide-werte-logo"> <img src='../assets/img/Group-2.svg'></div>
        <div><h2 class="slide-h2-werte">{{$t('message.card2H2') }}</h2></div>
      </div>
      <div>
        <p class="slide-p-werte">{{$t('message.card2') }}</p>
      </div>
    </div>
    <div class="slide-werte-box">
      <div>
        <div class="slide-werte-logo"> <img src='../assets/img/Layer2-2.svg'></div>
        <div><h2 class="slide-h2-werte">{{ $t('message.card3H2') }}</h2></div>
      </div>
      <div>
        <p class="slide-p-werte">{{ $t('message.card3') }}</p>
      </div>
    </div>
    <div class="slide-werte-box">
      <div>
        <div class="slide-werte-logo"> <img src='../assets/img/Vector-2.svg'></div>
        <div><h2 class="slide-h2-werte">{{ $t('message.card4H2') }}</h2></div>
      </div>
      <div>
        <p class="slide-p-werte">{{ $t('message.card4') }}</p>
      </div>
    </div>
    <div class="slide-werte-box">
      <div>
        <div class="slide-werte-logo"> <img src='../assets/img/Group2471-2.svg'></div>
        <div><h2 class="slide-h2-werte">{{ $t('message.card1H2') }}</h2></div>
      </div>
      <div>
        <p class="slide-p-werte">{{ $t('message.card1') }}</p>
      </div>
    </div>
  </div>

</template>

<style scoped>
.TextBox-tablet{
  display: none;
}
.container{
  display: flex;
  max-height: 485px;
  gap: 80px;
  margin-bottom: 150px;
}
.slide-werte-container{
  display: none;
}

.container-mobile{
  display: none;
}
.logo{
  width: 550px;
  height: 550px;
}
h1{
  font-family: "Open Sans";
  color: #DF1A12;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 150px;
  margin-bottom: 60px;
}
h2{
  color: var(--Black, #212429);
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
p{
  font-family: Roboto;
  color: #646465;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.img{
  flex: 1;
}
.TextBox{
  width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 38px;
  justify-content: center;
  width: 1260px;
  height: 692px ;
}
.card {
  display: flex;
  max-width: 523px;
  max-height: 290px;
  padding: 20px 44px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  background-color: #fff;
  align-items: center;
}
.card-icon {
  flex: 0 0 100px;
  margin-right: 62px;
  height: 130px;
  width: 83px;
}
.card-text {
  flex: 1;
  text-align: left;
}
@media (max-width: 1260px) {
  .container{
    gap: 71px;
    margin-bottom: 48px;
    padding: 0;
    position: relative;
  }
  .slide-h2-werte{
    color: #252525;
    text-align: left;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 33px 0 0 0;
  }
  .slide-p-werte{
    color: #646465;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .slide-werte-container{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    max-width: 878px;
    margin: 0 auto;
  }
  .slide-werte-box{
    display:  flex;
    flex-direction: column;
    width: 325px;
    height: 432px;
    align-items: flex-start;
    margin: 20px;
    padding: 29px 28px 29px 28px;
    scroll-snap-align: center;
    scroll-behavior: smooth;
    border-radius: 8px;
    gap: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  .slide-werte-logo{
    width: 315px;
    text-align: left;
  }
  img{
    height: 100px;
  }
  .card-container{
    display: none;
  }
  .p-mobile{
    padding: 0 40px 0 40px;
    margin: 0 0 0 0;
  }
  .btn-mr-tablet {

    color: black;
    border: none;
    background: linear-gradient(rgba(255, 255, 255, 0.8), white);
    width: 100%;
    height: 100px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    position: absolute; /* Positioniert den Button absolut */
    bottom: 0; /* Am unteren Rand ausrichten */
    left: 0; /* Am linken Rand ausrichten */
    font-size: 20px;
  }
  .TextBox-tablet{
    display: block;
    position: relative;
    flex: 1;
    text-align: left;
  }
  .TextBox{
    display: none;
  }
.logo{
  width: 391px;
  height: 351px;
  }
}
@media (max-width: 768px) {
  .slide-h2-werte{
    text-align: left;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .slide-p-werte{
    color: #565657;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }
  .slide-werte-container{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    max-width: 768px;
    margin: 0 auto;
    padding: 10px 10px;
  }
  .slide-werte-box{
    display:  flex;
    flex-direction: column;
    width: 206px;
    height: 332px;
    align-items: flex-start;
    margin: 10px;
    padding: 29px 28px 19px 28px;
    scroll-snap-align: center;
    scroll-behavior: smooth;
    border-radius: 8px;
    gap: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  .slide-werte-logo{
    width: 168px;
    text-align: left;
  }
  .TextBox-tablet{
    display: none;
  }
  .card-container{
  display: none;
  }
  .container{
    display: none;
  }
  .container-mobile{
    display: block;
  }
  TextBox-mobile{
    padding: 40px;
  }
  img-mobile{
    flex: 1;
  }
  button{
    border: none;
    background-color: white;
    position: absolute;
    margin: -30px 0 20px 60px;
    font-size: 20px;
  }
  .btn-mr {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: linear-gradient(rgba(255, 255, 255, 0.8), white);
    width: 100%;
    height: 100px;
    padding: 0;
    font: inherit;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: -90px 0 0 0;
  }
  .logo{
    width: 250px;
    height: 250px;
    margin-bottom: 50px;
  }
  .card {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 175px;
    height: 350px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #fff;
    align-items: center;
    margin: 25px 0 25px 0;
  }

  .card-icon {
    flex: 0 0 0;
    height: auto;
    margin-right: 0;
    padding-left: 0;
  }

  .card-text {
    flex: 1;
    text-align: center;
  }

  h1{
    font-size: 25px;
    margin-top: 50px;
  }
  h2{
    font-size: 20px;
  }
  p{
    font-size: 15px;
  }
  .p-mobile{
    padding: 0 20px 0 20px;
    margin: 0 0 0 0;
    text-align: left;
  }
  img{
    height: 72px;
  }

}
</style>


<script>
export default {
  data() {
    return {
      firstParagraphTablet:
          "Die Kombination von IT und Sports in unserem Firmennamen lässt bereits vermuten, dass sich unsere Projekte " +
          "nicht in eine Schublade stecken lassen." + "Unsere breitgefächerte Ausrichtung von „Special IT-Services“, " +
          "sowohl national als auch international, zeigt " +
          "sehr gut in welchen Bereichen unsere Stärken und Kompetenzen" +
          "liegen. In unserer unabhängigen und inhabergeführten Firma arbeiten derzeit 26 Mitarbeiter an spannenden und " +
          "herausfordernden Projekten und bilden dabei eine kreative Mischung aus Technologieexperten, Softwareentwicklern " ,
      secondParagraphTablet:
          "und innovativen Mitarbeitern, welche als Team die Verantwortung für den nachhaltigen Erfolg unserer Firma tragen. " +
          "Mit unserem ganzheitlichen Blick für ein Projekt, der langjährigen Erfahrung und ausgeprägten Kundenorientierung " +
          "sind wir in der Lage unsere Projekte auf die interessanten Aufgaben unserer Partner individuell anzupassen.",
      showMore: false,
    };
  },
  methods: {
    toggleReadMore() {
      this.showMore = !this.showMore;
    },
  },

};
</script>